@font-face {
    font-family: "outfit_regular";
    font-style: normal;
    font-weight: normal;
    src: url(../fonts/Outfit-Regular.woff) format("woff");
}

@font-face {
    font-family: "outfit_medium";
    font-style: normal;
    font-weight: normal;
    src: url(../fonts/Outfit-Medium.woff) format("woff");
}

@font-face {
    font-family: "outfit_bold";
    font-style: normal;
    font-weight: normal;
    src: url(../fonts/Outfit-Bold.woff) format("woff");
}
@font-face {
    font-family: "outfit_light";
    font-style: normal;
    font-weight: normal;
    src: url(../fonts/Outfit-Light.woff) format("woff");
}
* {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    background: 0 0;
    font-weight: 400;
    font-style: normal;
    text-decoration: none;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    font-family: outfit_regular;
}
input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="text"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;
}
input[type="button"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
input[type="number"] {
    -moz-appearance: textfield;
}
ol,
ul,
li {
    list-style: none;
}
blockquote {
    quotes: none;
}
blockquote:after,
blockquote:before {
    content: "";
    content: none;
}
del {
    text-decoration: line-through;
}
address,
article,
aside,
footer,
header,
nav,
section {
    display: block;
}
body {
    font-family: "outfit_regular";
    font-size: 17px;
    overflow-x: hidden;
    background: #f5f7fb;
}

body::-webkit-scrollbar {
    display: none !important;
}

input,
select,
textarea {
    font-family: "outfit_regular";
}
html {
    overflow: scroll !important;
    scroll-behavior: smooth !important;
}
html.modal-enabled {
    overflow: hidden !important;
}

@media all and (max-width: 480px) {
    html ::-webkit-scrollbar {
        display: none;
    }
}

a {
    color: #444;
}
.submit:hover,
a:hover {
    -moz-opacity: 0.85;
    -khtml-opacity: 0.85;
    opacity: 0.85;
}
.thumb {
    display: block;
}
.thumb img {
    display: block;
    width: 100%;
}
p {
    line-height: 1.6em;
    font-size: 16px;
    color: #868686;
    font-family: "outfit_regular";
}
#main {
    margin-left: 80px;
    margin-top: 89px;
    max-width: 100%;
    margin-right: 15px;
}
@media all and (max-width: 768px) {
    #main {
        margin-left: 15px;
    }
}

.tooltip---tooltip-inner---AMsNF {
    background: rgba(255, 255, 255, 0) !important;
    box-shadow: none !important;
    border: none !important;
}

/* PAGINATION */

.pagination {
    display: flex;
    align-items: center;
}
@media all and (max-width: 768px) {
    .pagination {
        margin-top: 10px;
    }
}
@media all and (max-width: 380px) {
    .pagination {
        flex-wrap: wrap;
        justify-content: center !important;
    }
    .pagination li {
        margin-top: 15px;
    }
}

.pagination a {
    padding: 8px 14px;
    color: #161619;
    font-size: 13px;
    display: inline-block;
    background-color: #f5f7fb;
    margin: 0px 3px;
    cursor: pointer;
}
.previous {
    background-image: url("https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-admin-images%2Fpagination-left-newarrow.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 13px;
    height: 15px;
    background-color: transparent;
    margin-right: 10px;
}
.next {
    background-image: url("https://stikcon-website.sgp1.digitaloceanspaces.com/stikcon-admin-images%2Fpagination-right-newarrow.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 13px;
    height: 15px;
    background-color: transparent;
    margin-left: 10px;
}
.pagination li.break a {
    display: inline-block;
}
.previous .pagination__link {
    background: transparent;
}
.next .pagination__link {
    background: transparent;
}
.pagination__link {
    font-weight: bold;
}
.pagination__link--active a {
    color: #fff !important;
    background-color: #4cb0ff;
}
.pagination__link--disabled a {
    cursor: not-allowed;
}
.active_link_classname {
    color: #fff;
    background: #48538d;
}
.ecAamZ {
    padding: 10px 0 15px !important;
}

.css-1s2u09g-control {
    height: 50px !important;
    box-shadow: none !important;
    border-color: #bebebe !important;
    color: #707070 !important;
    outline-color: #0884df !important;
}
.css-1pahdxg-control:hover {
    box-shadow: none !important;
    color: #707070 !important;
    outline-color: #0884df !important;
}
.css-1pahdxg-control {
    height: 50px !important;
    border-color: #bebebe !important;
    box-shadow: none !important;
    outline-color: #0884df !important;
}
.css-qc6sy-singleValue {
    color: #707070 !important;
}
h1,
h2,
h3,
h4 {
    margin-bottom: 0 !important;
}

.css-qc6sy-singleValue {
    cursor: pointer !important;
}
.input-drop {
    width: 100%;
    margin-left: 50px;
    background: #fff9f9;
    outline-color: #0884df !important;
    max-height: 250px;
    overflow-y: scroll !important;
    padding-left: 0 !important;
}
.input-option {
    padding: 12px 18px;
    cursor: pointer;
    transition: 0.4s ease;
    outline-color: #0884df !important;
}
.select-option .css-1s2u09g-control {
    height: 50px !important;
    box-shadow: none !important;
    border-color: #022334 !important;
    outline-color: #0884df !important;
    color: #707070 !important;
    background: #042941 !important;
    width: 100%;
    margin-bottom: 7px;
}
.css-1s2u09g-control {
    height: 50px !important;
    box-shadow: none !important;
    color: #707070 !important;
    outline-color: #0884df !important;
    cursor: pointer;
}
.css-1pahdxg-control:hover {
    box-shadow: none !important;
    outline-color: #0884df !important;
    color: #707070 !important;
}
.css-1pahdxg-control {
    height: 50px !important;
    outline-color: #0884df !important;
    box-shadow: none !important;
    outline-color: #0884df !important;
}
.css-qc6sy-singleValue {
    color: #707070 !important;
    font-size: 14px !important;
}
.react-reveal {
    width: 100% !important;
}

/* React Crop */
.ReactCrop {
    display: flex !important;
    justify-content: center !important;
    border-radius: 5px;
}
.ReactCrop__crop-selection {
    border: 1px solid #fff !important;
}
.ReactCrop__drag-handle::after {
    background: #fff !important;
    border-radius: 50% !important;
}
.ReactCrop .ord-n::after {
    left: 0 !important;
}
.ReactCrop .ord-s::after {
    left: 0 !important;
}
